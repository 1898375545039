import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Star from '@material-ui/icons/Star'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import { currency, paddedNumber } from 'utils/numberFormat'
import { addToFavorite, removeFromFavorite, updateCompany as updateCompanyApi } from 'api'
import { queryCache, useMutation, useQuery } from 'react-query'
import { CompanyEdit } from './CompanyEdit'
import { InvestmentCreate } from 'components/investment'
import { useSetState } from 'react-use/lib/index'
import { ConfirmationDialog, TextTooltip } from 'components'
import { apiErrors } from 'utils/error'
import { useHistory } from 'react-router-dom'
import { getAllInvestments } from 'api'
import { tileValuesCalculation } from 'utils/company'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(12),
  },
  gridList: {
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  card: {
    minHeight: '350px',
    padding: theme.spacing(2, 3, 2, 4),
    boxShadow: theme.customShadows.shadow1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grayShades.gray6,
    },
  },
  cardHeader: {
    padding: 0,
  },
  cardTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardTitleText: {
    maxWidth: '175px',
  },
  cardContent: {
    padding: theme.spacing(1, 0),
  },
  currencyText: {
    paddingBottom: theme.spacing(2),
  },
  favoriteIcon: {
    color: theme.palette.common.yellow,
    marginLeft: theme.spacing(0.5),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

const isFavoriteCompany = company => company.company_subscribers && company.company_subscribers.length

export function CompanyList({ companies = [] }) {
  const classes = useStyles()
  const history = useHistory()
  const toastService = useToast()

  const [updateCompany, { isLoading: updatingCompany }] = useMutation(updateCompanyApi, { throwOnError: true })
  const { data: investments = [] } = useQuery(['allInvestments'], getAllInvestments)

  const [{ openCreateInvestmentModal, investmentCompany, selectedCompany, openConfirmation }, setState] = useSetState({
    openCreateInvestmentModal: false,
    investmentCompany: undefined,
    selectedCompany: null,
    openConfirmation: false,
  })

  const investmentsByCompany = investments
    .filter(investment => investment.status.name === 'active')
    .reduce((investmentsByCompany, investment) => {
      const companyId = investment.company_id
      const companyInvestments = investmentsByCompany[companyId] || []

      investmentsByCompany[companyId] = [...companyInvestments, investment]
      return investmentsByCompany
    }, {})

  const handleConfirm = () => {
    setState({ openConfirmation: false, selectedCompany: null })
  }

  const handleCancel = () => {
    setState({ openConfirmation: false })
  }

  const handleEditClick = company => setState({ selectedCompany: company })

  const handleEditSubmit = async updatedCompany => {
    try {
      await updateCompany({ company: updatedCompany, companyId: selectedCompany.id })
      queryCache.invalidateQueries('companies')
      queryCache.invalidateQueries('favoriteCompanies')
      setState({ selectedCompany: null })
      toastService.showSuccessToast(`Successfully updated company ${updatedCompany.name}.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to update company ${updatedCompany.name}. ${e.message}`)
      return apiErrors(e)
    }
  }

  const handleAddInvestmentClick = company => {
    setState({ anchorEl: null, openCreateInvestmentModal: true, investmentCompany: company })
  }

  const toCompanyDetails = companyId => () => {
    history.push(`/companies/details/${companyId}`)
  }

  const handleEditClose = (event, dirty) => {
    if (dirty) {
      setState({ openConfirmation: true })
    } else {
      setState({ selectedCompany: null, openConfirmation: false })
    }
  }

  return (
    <div className={classes.root}>
      <GridList spacing={24} cols={4} className={classes.gridList} cellHeight={350}>
        {companies.map(company => {
          const companyValues = tileValuesCalculation(investmentsByCompany[company.id], company)

          return (
            <Grow key={company.id} in>
              <GridListTile className={classes.tile}>
                <Card className={classes.card} onClick={toCompanyDetails(company.id)}>
                  <CardHeader
                    title={
                      <Grid container>
                        <Grid container item xs={10} className={classes.cardTitle} alignItems="center">
                          <TextTooltip title={company.name}>
                            <Typography variant="h6" noWrap className={classes.cardTitleText}>
                              {company.name}
                            </Typography>
                          </TextTooltip>
                          {isFavoriteCompany(company) ? <Star className={classes.favoriteIcon} /> : null}
                        </Grid>
                        <Grid container item xs={2} justify="flex-end">
                          <CompanyActions
                            company={company}
                            onEditClick={handleEditClick}
                            handleAddInvestmentClick={handleAddInvestmentClick}
                          />
                        </Grid>
                      </Grid>
                    }
                    disableTypography
                    classes={{ root: classes.cardHeader }}
                  />

                  <CardContent classes={{ root: classes.cardContent }}>
                    <Grid item xs={9} className={classes.currencyText}>
                      <Typography variant="h6" className={classes.noWrap}>{`£ ${paddedNumber(
                        companyValues.fullyDilutedValuation
                      )}`}</Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Fully diluted valuation at current marketed share price
                      </Typography>
                    </Grid>

                    <Grid container className={classes.currencyText} spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          {`£ ${paddedNumber(companyValues.totalInvested)}`}
                        </Typography>

                        <Typography variant="subtitle1" color="textSecondary">
                          Total invested Equity by Fig investors
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          {`£ ${currency(company.current_market_price)}`}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Current marketed share price
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={9}>
                      <Typography variant="body1" color="textSecondary">
                        {`£ ${paddedNumber(companyValues.totalPrincipal)}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Carrying value of Fig investors debt and accrued interest
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </GridListTile>
            </Grow>
          )
        })}
      </GridList>

      <CompanyEdit
        company={selectedCompany}
        handleClose={handleEditClose}
        handleSubmit={handleEditSubmit}
        isLoading={updatingCompany}
      />

      <InvestmentCreate
        open={openCreateInvestmentModal}
        handleCancel={() => setState({ openCreateInvestmentModal: false, investmentCompany: null })}
        company={investmentCompany}
        disableCompanySelect
      />

      <ConfirmationDialog
        open={openConfirmation}
        title="Cancel Company Editing"
        onAccept={handleConfirm}
        onCancel={handleCancel}
        content={`You are going to cancel ${selectedCompany && selectedCompany.name}
        editing. All unsaved changes will be lost.`}
      />
    </div>
  )
}

const CompanyActions = ({ company, onEditClick, handleAddInvestmentClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [addFavorite] = useMutation(addToFavorite)
  const [removeFavorite] = useMutation(removeFromFavorite)
  const toastService = useToast()

  const handleClick = event => {
    event.persist()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.persist()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleFavoriteClick = company => async event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()

    if (isFavoriteCompany(company)) {
      await removeFavorite(company.id)
      toastService.showInfoToast(`Removed company ${company.name} from 'My Companies'`)
    } else {
      await addFavorite(company.id)
      toastService.showSuccessToast(`Added company ${company.name} to 'My Companies'`)
    }

    queryCache.invalidateQueries('companies')
    queryCache.invalidateQueries('favoriteCompanies')

    handleClose(event)
  }

  const handleEditClick = company => event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()
    onEditClick({ ...company, isFavorite: isFavoriteCompany(company) })
    handleClose(event)
  }

  const handleInvestmentClick = company => event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()
    handleAddInvestmentClick(company)
    handleClose(event)
  }

  return (
    <>
      <IconButton aria-label="settings" aria-haspopup="true" onClick={e => handleClick(e)}>
        <MoreHoriz />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={1}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleFavoriteClick(company)}>
          {isFavoriteCompany(company) ? 'Remove From "My Companies"' : 'Add to "My Companies"'}
        </MenuItem>
        <MenuItem onClick={handleEditClick(company)}>Edit Company</MenuItem>
        <MenuItem onClick={handleInvestmentClick(company)}>Add Investment</MenuItem>
      </Menu>
    </>
  )
}
